<template>
	<div>
		<div class="row mb-3">
			<div class="col-12">
				<h1><em :class="getIcon(policy.buscode)" v-if="!loading"></em> Policy {{policy.policynum}}</h1>
			</div>
		</div>

		<div class="text-center" v-show="loading"><em class="fas fa-circle-notch fa-spin fa-3x"></em><br>Loading Policy...</div>

		<table class="table table-striped table-bordered" v-if="!loading">
			<tbody>
				<tr>
					<td><b>Policy Number</b></td>
					<td>{{policy.policynum}}</td>
				</tr>
				<tr>
					<td><b>Policy Type</b></td>
					<td><em :class="getIcon(policy.buscode)"></em> {{policy.buscode}}</td>
				</tr>
				<tr>
					<td><b>Insured</b></td>
					<td>{{policy.name}}</td>
				</tr>
				<tr>
					<td><b>Insurer</b></td>
					<td>{{policy.insurance_company}}</td>
				</tr>
				<tr>
					<td><b>Effective</b></td>
					<td>{{policy.effective + "T09:00:00" | longDate}}</td>
				</tr>
				<tr>
					<td><b>Expiry</b></td>
					<td>{{policy.renewal + "T09:00:00" | longDate}}</td>
				</tr>
				<tr>
					<td><b>Premium</b></td>
					<td>${{number_format(policy.prem_amt, 2)}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default
{
	name: 'Policy',
	props: ["session_id", "logged_in"],
	data: function()
	{
		return {
			policy: [],
			loading: true
		}
	},
	mounted: function()
	{
		if(!this.logged_in)
		{
			return;
		}

		this.loadPolicy(this.$route.params.policynum);
	},
	methods:
	{
		loadPolicy: function(policynum)
		{
			var vm = this;
			vm.loading = true;

			this.CORS("GET", "/policy/" + policynum, null,
			function(response)
			{
				vm.policy = response;
				vm.loading = false;
			}, function(response)
			{
				vm.showError("Error Loading Policy", response.responseText, true, null);
				vm.loading = false;
			})
		}
	},
	watch:
	{
		'$route' (from, to)
		{
			if(from == to)
			{
				return;
			}

			this.loadPolicy(this.$route.params.policynum);
		}
	}
}
</script>
